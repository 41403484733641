import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdvancedFilterInput, FilterCriteria } from '@shared/models/types/advanced-filter-input';
import { cloneDeep, isEqual } from 'lodash-es';
import { NotificationService } from './notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DocumentContext } from '@shared/models/types/documents';
import { getSuitableFilter, hasSuitableFilter, replaceFilterValue } from '@shared/utils/list.utils';
import { CriteriaOperator, FilterNodeType } from '@shared/models/types/types.generated';

@Injectable()
export abstract class FilterService<K> {
  private notificationService: NotificationService = inject(NotificationService);
  private clipboard: Clipboard = inject(Clipboard);

  private defaultFilters = this.getDefaultFilters();
  readonly context: DocumentContext;

  private _filters: BehaviorSubject<AdvancedFilterInput<K>> = new BehaviorSubject<AdvancedFilterInput<K>>(
    this.defaultFilters,
  );
  readonly filters$ = this._filters.asObservable();

  private _searchTerm = new BehaviorSubject<string>('');
  readonly searchTerm$ = this._searchTerm.asObservable();

  abstract getDefaultFilters(): AdvancedFilterInput<K>;

  get filters() {
    return this._filters.getValue();
  }

  get searchTerm() {
    return this._searchTerm.getValue();
  }

  updateFilter(filterValues: AdvancedFilterInput<K>) {
    if (isEqual(this._filters.getValue(), filterValues)) {
      return;
    }

    this._filters.next(filterValues);
  }

  addValueToFilter(key: FilterCriteria<K>['key'], value: string) {
    let filters = cloneDeep(this.filters);

    if (!hasSuitableFilter(filters.rootNode, key)) {
      filters.rootNode.children.push({
        type: FilterNodeType.Leaf,
        value: {
          key,
          operator: CriteriaOperator.In,
          value,
        },
      });
    } else {
      const skillFilter = getSuitableFilter(filters.rootNode, key);

      const currentValue = skillFilter.value.value;

      if (currentValue.split(',').filter((cv: string) => cv === value).length === 0) {
        filters = { rootNode: replaceFilterValue(filters.rootNode, currentValue, `${currentValue},${value}`) };
      }
    }

    this.updateFilter(filters);
  }

  removeValueFromFilter(key: FilterCriteria<K>['key'], value: string) {
    const filters = cloneDeep(this.filters);

    if (!hasSuitableFilter(filters.rootNode, key)) {
      return;
    }

    const skillFilter = getSuitableFilter(filters.rootNode, key);
    const currentValue = skillFilter.value.value;

    const newValue = currentValue
      .split(',')
      .filter((cv: string) => cv !== value)
      .join(',');

    if (newValue === '') {
      filters.rootNode.children = filters.rootNode.children.filter((node) => node.value.key !== key);
    } else {
      filters.rootNode = replaceFilterValue(filters.rootNode, currentValue, newValue);
    }

    this.updateFilter(filters);
  }

  resetFilters() {
    this.updateFilter(this.getDefaultFilters());
  }

  updateSearchTerm(searchTerm: string) {
    this._searchTerm.next(searchTerm);
  }

  isFilterKey(key: string): boolean {
    return key === 'rootNode';
  }

  copyUrlToClipboard() {
    if (this.clipboard.copy(window.location.href)) {
      this.notificationService.addSuccessNotification('common:clipboard.copyLink.success');
    }
  }
}
